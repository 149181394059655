<template>
  <div class="text-center">
    <v-dialog v-model="show" width="500" persistent>
      <v-card>
        <v-overlay :absolute="true" :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-toolbar color="#9B0A00" elevation="0">
          <v-toolbar-title class="white--text">Remove User</v-toolbar-title>
        </v-toolbar>
        <div class="p-4">
          <p class="mb-1">Are you sure you want to delete?</p>
          <div class="mb-1">
            <small class="text-caption text-secondary text-uppercase d-block">User</small>
            <p class="mb-0"> {{ form.name }} </p>
          </div>
          <div class="mb-1">
            <small class="text-caption text-secondary text-uppercase d-block">company</small>
            <p class="mb-0"> {{ form.company }} </p>
          </div>
          <div class="mb-1">
            <small class="text-caption text-secondary text-uppercase d-block">role</small>
            <p class="mb-0"> {{ form.role | role }} </p>
          </div>
          <div class="mb-1">
            <small class="text-caption text-secondary text-uppercase d-block">status</small>
            <p class="mb-0"> {{ uppercase(form.status) }} </p>
          </div>
          <p class="red--text caption mb-0"> Deleting will not allow this to be recovered* </p>
        </div>
        <v-card-actions class="border-top">
          <div class="w-100 text-right">
            <v-btn text class="text-secondary" @click="cancel"  :disabled="overlay">Cancel</v-btn>
            <v-btn  text color="#9B0A00" @click="remove" :loading="overlay">Delete</v-btn>
          </div>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script type="text/javascript">
export default {
  props: {
    dialog: Boolean,
    recievedData: Object,
  },
  data() {
    return {
      overlay: false,
      form: {
        id: null,
        name: null,
        department: null,
        company: null,
        role: null,
        status: null,
      },
    };
  },
  computed: {
    show: {
      get() {
        if (this.recievedData) {
          this.populate();
        }
        return this.dialog;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    uppercase(string) {
      if (string) {
        return string.charAt(0).toUpperCase() + string.substring(1);
      }
    },
    cancel() {
      this.show = false;
    },
    remove() {
      this.overlay = true;
      this.axios.delete("/userscompanies/" + this.form.id).then(() => {
        this.overlay = false;
        this.cancel();
        this.$emit("refresh", true);
      });
    },
    populate() {
      this.form.id = this.recievedData.id;
      this.form.department = this.recievedData.name;
      this.form.company = this.recievedData.company.name;
      this.form.role = this.recievedData.role;
      this.form.name =
        this.recievedData.user.first_name +
        " " +
        this.recievedData.user.last_name;
      this.form.status = this.recievedData.status;
    },
  },
};
</script>