<template>

  <v-card flat>
    <v-card-text>
      <v-row dense>
          <v-col cols="12" md="5">
            <small class="text-caption text-secondary">TITLE</small>
            <p class="mb-0 h5 black--text">COMPANY PROJECTS</p>
          </v-col>
          <v-col cols="12" md="7" class="">
            <small class="text-caption text-secondary">PROJECTS</small>
            <p class="mb-0 h5 black--text" >{{pagination.meta ? pagination.meta.total : 0 }}</p>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field 
            v-model="search" 
            color="#0A009B"
            hide-details="auto"
            label="Search"
            hint="Search for Project Name, Type "
            persistent-hint
            prepend-icon="mdi-text-box-search-outline"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" >
            <v-btn color="#0A009B" text block @click="create" min-width="100px" :loading="loading.create"> New Project </v-btn>          
          </v-col>
        </v-row>
    </v-card-text>
    <v-data-table 
      id="projectTable"
      :headers="headers" 
      :items="filterProject"
      :hide-default-footer="true" 
      :loading='loading.table' 
      loading-text="Loading... Please wait">
      <template v-slot:[`item.list`]="{item}">
        <div v-if="concatinate(item.users)">{{ concatinate(item.users) }}</div>
        <small v-else class="text-secondary">Empty</small>
      </template>
      <template v-slot:[`item.disturb`]="{item}">
        {{item.disturb ? 'Yes' : 'No' }}
      </template>
      <template v-slot:[`item.action`]="{item}">
          <v-icon color="#0A009B" small class="m-2 p-1" @click="edit(item)">mdi-pencil</v-icon>
          <v-icon color="#9B0A00" small class="m-2 p-1" @click="remove(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <Pagination v-bind:meta="pagination.meta" @page="paginate"> </Pagination>

    <Edit v-bind:admin="arrays.me" v-model="dialogs.edit" v-bind:dialog="dialogs.edit" v-bind:recievedData='dialogs.data' @refresh="refresh" v-bind:usersList="arrays.users"></Edit>
    <Delete v-model="dialogs.delete" v-bind:dialog="dialogs.delete" v-bind:recievedData='dialogs.data' @refresh="refresh"> </Delete>
    <Create v-bind:myInfo="arrays.me" v-model="dialogs.create" v-bind:dialog="dialogs.create" v-bind:recievedData='companyInfo' @refresh="refresh" v-bind:usersList="arrays.users"> </Create>
  </v-card>
</template>
<script type="text/javascript">
  import Edit from './edit'
  import Delete from './delete'
  import Create from './create'
  import Pagination from '@/components/Pagination'
  export default {
    props: ['company', 'myInfo', 'query'],
    components:{
      Edit,
      Delete,
      Create,
      Pagination
    },
    data() {
      return {
        arrays : {
          projects : [],
          users: [],
          me : null
        },
        companyInfo : [],
        search: "",
        loading: {
          table: true,
          create : true
        },
        headers : [
          { text: 'Project Name', value: 'name' },
          { text: 'Type', value: 'type' },
          { text: 'Percentage', value: 'percentage',  },
          { text: 'Disturb', value: 'disturb' },
          { text: 'Users', value: 'list', filterable: false, sortable : false  },
          { text: 'Action', value: 'action', filterable: false, sortable : false  }
        ],
        request : {
          projects : {company_id : null }
        },
        dialogs : {
          edit: false,
          delete: false,
          create: false,
          data : null
        },
        pagination: {
          meta : null,
          current: null
        }
      }
    },
    created()
    {
      this.companyInfo = this.company
      this.getProject()
      this.getUsers()
      if(this.myInfo)
      {
        this.arrays.me = this.myInfo
      }
      if(this.query)
      {
        if(this.query.tab == 'projects')
        {
          this.search = this.query.search
        }
      }
    },
    watch: {
      myInfo : function(val)
      {
        if(val)
        {
          this.arrays.me = val
        }
      }
    },
    methods : {
      getProject(page)
      {
        let p = ''
        if(page)
        {
          p = page
        }
        this.axios.get('/projects?company_id='+this.company.id+'&page='+p)
        .then(({data}) => {
          this.loading.table = false
          this.arrays.projects = data.projects
          this.pagination.meta = data.meta
          if(page)
          {
            if(window.innerWidth < 600)
            {
              document.getElementById('projectTable').scrollIntoView()
            }
          }
        })
      },
      edit(data)
      {
        this.dialogs.data = data
        this.dialogs.edit = true
      },
      remove(data)
      {
        let con = this.concatinate(data.users)
        data['list'] = con
        this.dialogs.data = data
        this.dialogs.delete = true
      },
      paginate(page)
      { 
        this.getProject(page)
      },
      create()
      {
        this.dialogs.create = true
      },
      refresh()
      {
        this.getProject()
        this.$emit('upateKey', 'projects')
      },
      getUsers()
      {
        this.axios.get('/userscompanies?company_id='+this.company.id+'&per_page=1')
        .then(({data}) => {
          this.getUsersAll(data.meta.total)
        })
      },
      getUsersAll(total)
      {
        this.axios.get('/userscompanies?company_id='+this.company.id+'&per_page='+total)
        .then( ({data}) => {
          this.arrays.users = data.users_company
          this.loading.create = false
        })
      },
      concatinate(users)
      {
        let concat = ''
        if (users.length > 0) {
          for(var i = 0 ; i <= users.length; i++) {
            if (users[i]) {
              if (users[i].user_company) {
                concat += ( users[i].user_company.user.first_name + ' ' +  users[i].user_company.user.last_name + ', ')
              }
            }
          }
        }
        if (concat) {
          concat = concat.substring(0, concat.length - 2);
        }
          return concat
        }
    },
    computed: {
      filterProject()
      {
        return this.arrays.projects.filter( project => {
          return project.name.toLowerCase().match(this.search.toLowerCase() ) 
              || project.percentage == this.search
              || project.type.match(this.search)
        });
      }
    }
  }
</script>


<style scoped>
#btn{
  text-transform: none;
}
  
</style>