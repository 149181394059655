<template>
  <v-card flat>
    <v-card-text>
       <v-row dense>
          <v-col cols="12" md="4">
            <small class="text-caption text-secondary">TITLE</small>
            <p class="mb-0 h5 black--text">DEPARTMENTS</p>
          </v-col>
          <v-col cols="12" md="6" class="">
            <small class="text-caption text-secondary">DEPARTMENTS</small>
            <p class="mb-0 h5 black--text" >{{ pagination.meta ? pagination.meta.total : 0 }}</p>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field 
            
            v-model="search" 
            color="#0A009B"
            hide-details="auto"
            label="Search"
            hint="Search for Name"
            persistent-hint
            prepend-icon="mdi-layers-search-outline"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" class="text-center">
            <v-btn color="#0A009B" block text  @click="create" min-width="100px" :loading="loading.create"> New Department </v-btn>          
          </v-col>
        </v-row>
    </v-card-text>
    <v-data-table 
      id="departmentTable"
      :headers="headers" 
      :items="filterDepartments" 
      :loading='loading'
      :hide-default-footer="true" 
      loading-text="Loading... Please wait">
      <template v-slot:[`item.action`]="{item}">
          <v-icon color="#0A009B" small class="mr-2 p-1" @click="edit(item)">mdi-pencil</v-icon>
          <v-icon color="#9B0A00" small class="mr-2 p-1" @click="remove(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <Pagination v-bind:meta="pagination.meta" @page="paginate" class="mt-0"> </Pagination>
    <Edit v-model="dialogs.edit" v-bind:dialog="dialogs.edit" v-bind:recievedData='dialogs.data' @refresh="refresh"></Edit>
    <Delete v-model="dialogs.delete" v-bind:dialog="dialogs.delete" v-bind:company="arrays.company" v-bind:recievedData='dialogs.data' @refresh="refresh"> </Delete>
    <Create v-model="dialogs.create" v-bind:dialog="dialogs.create" v-bind:company="arrays.company" v-bind:recievedData='arrays.company' @refresh="refresh"> </Create>
  </v-card>
</template>
<script type="text/javascript">
import Edit from "./edit";
import Delete from "./delete";
import Create from "./create";
import Pagination from "@/components/Pagination";
export default {
  components: {
    Edit,
    Delete,
    Create,
    Pagination,
  },
  props: ["company", 'query'],
  data() {
    return {
      arrays: {
        departments: [],
        company: {},
      },
      search: "",
      loading: false,
      headers: [
        { text: "Department", value: "name" },
        { text: "Action", value: "action", filterable: false, sortable : false  },
      ],
      dialogs: {
        edit: false,
        delete: false,
        create: false,
        data: null,
      },
      pagination: {
        current: null,
        meta: null,
      },
    };
  },
  created() {
    this.arrays.company = this.company;
    this.getDepartment();
    if(this.query)
    {
      if(this.query.tab == 'departments')
      {
        this.search = this.query.search
      }
    }
  },
  methods: {
    getDepartment() {
      this.loading = true;
      this.axios
        .get("/companydepartments?company_id=" + this.arrays.company.id)
        .then(({ data }) => {
          this.loading = false;
          this.arrays.departments = data.company_departments;
          this.pagination.meta = data.meta;
        });
    },
    edit(data) {
      this.dialogs.data = data;
      this.dialogs.edit = true;
    },
    remove(data) {
      this.dialogs.data = data;
      this.dialogs.delete = true;
    },
    create() {
      this.dialogs.create = true;
    },
    refresh() {
      if (this.pagination.current) {
        this.paginate(this.pagination.current);
      } else {
        this.getDepartment();
      }
      this.$emit('upateKey', 'departments')
    },
    paginate(page) {
      this.pagination.current = page;
      this.loading = true;
      this.axios
        .get(
          "/companydepartments?company_id=" +
            this.arrays.company.id +
            "&page=" +
            page
        )
        .then(({ data }) => {
          this.loading = false;
          this.arrays.departments = data.company_departments;

          if(window.innerWidth < 600)
          {
            document.getElementById('departmentTable').scrollIntoView()
          }
        });
    },
  },
  computed: {
    filterDepartments() {
      return this.arrays.departments.filter((department) => {
        return (
          department.name.toLowerCase().match(this.search.toLowerCase() ) ||
          department.company.name.toLowerCase().match(this.search.toLowerCase() )
        );
      });
    },
  },
};
</script>

<style scoped>
#btn {
  text-transform: none;
}
</style>