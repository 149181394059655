<template>
  <div class="text-center">
    <v-dialog v-model="show" width="400" persistent>
      <v-card>
         <v-toolbar color="#9B0A00" elevation="0">
          <v-toolbar-title class="white--text">Delete Department</v-toolbar-title>
        </v-toolbar>
        <div class="p-4">
          <p class="">Are you sure you want to delete?</p>
          <div class="mb-1">
            <small class="text-caption text-secondary text-uppercase d-block">department</small>
            <p class="mb-0"> {{ form.department}} </p>
          </div>
          <div class="mb-1">
            <small class="text-caption text-secondary text-uppercase d-block">from company</small>
            <p class="mb-0"> {{ form.company}} </p>
          </div>
        </div>
        <v-card-actions class="border-top">
            <div class="text-right w-100">
              <v-btn class="text-secondary" text @click="cancel" :disabled="overlay"> Cancel </v-btn>
              <v-btn text color="#9B0A00" @click="remove" :loading="overlay"> Delete </v-btn>
            </div>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script type="text/javascript">
export default {
  props: {
    dialog: Boolean,
    recievedData: Object,
  },
  data() {
    return {
      overlay: false,
      form: {
        id: null,
        department: null,
        company: null,
      },
    };
  },
  computed: {
    show: {
      get() {
        if (this.recievedData) {
          this.populate();
        }
        return this.dialog;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    cancel() {
      this.show = false;
    },
    remove() {
      this.overlay = true;
      this.axios.delete("/companydepartments/" + this.form.id).then(() => {
        this.overlay = false;
        this.cancel();
        this.$emit("refresh", true);
      });
    },
    populate() {
      this.form.id = this.recievedData.id;
      this.form.department = this.recievedData.name;
      this.form.company = this.recievedData.company.name;
    },
  },
};
</script>