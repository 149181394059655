<template>
  <div class="text-center">
    <v-dialog v-model="show" width="400" persistent>
      <v-card>
        <v-toolbar color="#9B0A00" elevation="0">
          <v-toolbar-title class="white--text">Remove User</v-toolbar-title>
        </v-toolbar>
      

          <div class="p-4">
          <p >Are you sure you want to delete?</p>
          <div class="mb-1">
            <small class="text-caption text-secondary text-uppercase d-block">user</small>
            <p class="mb-0"> {{ form.name}} </p>
          </div>
          <div class="mb-1">
            <small class="text-caption text-secondary text-uppercase d-block">department</small>
            <p class="mb-0"> {{ form.department}} </p>
          </div>
          <div class="mb-1">
            <small class="text-caption text-secondary text-uppercase d-block">position</small>
            <p class="mb-0"> {{ form.position}} </p>
          </div>
        </div>
        <v-card-actions class="border-top">
          <div class="text-right w-100">
            <v-btn class="text-secondary" @click="cancel" text :disabled="overlay">Cancel</v-btn>
            <v-btn color="#9B0A00" text @click="remove" :loading="overlay">delete</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script type="text/javascript">
export default {
  props: {
    dialog: Boolean,
    data: Object,
  },
  data() {
    return {
      overlay: false,
      form: {
        id: null,
        name: null,
        department: null,
        company: null,
        role: null,
        status: null,
      },
    };
  },
  computed: {
    show: {
      get() {
        if (this.data) {
          this.populate();
        }
        return this.dialog;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    cancel() {
      this.show = false;
    },
    remove() {
      this.overlay = true;
      this.axios.delete("/usercompanydepartments/" + this.form.id).then(() => {
        this.overlay = false;
        this.cancel();
        this.$emit("refresh", "d");
      });
    },
    populate() {
      this.form.id = this.data.id;
      this.form.department = this.data.deparments.name;
      this.form.position = this.data.position;
      this.form.name =
        this.data.user_company.user.first_name +
        " " +
        this.data.user_company.user.last_name;
    },
  },
};
</script>