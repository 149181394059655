<template>
  <v-card flat>
    <v-card-text>
      <v-row align="center">
          <v-col cols="12" lg="6" md="6">
            <small class="text-caption text-secondary">SEARCH</small>
            <v-text-field color="#0A009B"
              v-model="search"
              label="Search"
              hint="Enter : Name, Role, Supervisor"
              persistent-hint
              prepend-icon="mdi-account-search-outline"
            ></v-text-field>
          </v-col>
           <v-col cols="12" md="2" lg="2"  >
            <small class="text-caption text-secondary">TOTAL USERS</small>
            <p class=" h5 black--text mb-0" >
              {{ pagination.meta ? pagination.meta.total : 0  }}
            </p>
          </v-col>
        </v-row>
    </v-card-text>
    <v-data-table
      id="usersTable"
      :headers="headers"
      :items="filterUsers"
      :loading="loading"
      :hide-default-footer="true"
      loading-text="Loading... Please wait"
    >
      <template v-slot:[`item.name`]="{ item }">
        {{ item.user.first_name }} {{ item.user.last_name }} <span v-if="item.user_id == arrays.me.id"> ( <b>You</b> ) </span>
      </template>
      <template v-slot:[`item.parent`]="{ item }">
        <span v-if="item.parent"> {{ item.parent.user.first_name }} {{ item.parent.user.last_name }}  </span>
        <span v-else> N/A </span>
      </template>
      <template v-slot:[`item.role`]="{ item }">
        {{ item.role }} 
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-icon color="#0A009B" small class="mr-2 p-1" @click="edit(item)"
          >mdi-pencil</v-icon
        >
        <v-icon color="#9B0A00" small class="mr-2 p-1" @click="remove(item)" v-if="item.user_id != arrays.me.id"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <Pagination v-bind:meta="pagination.meta" @page="paginate"> </Pagination>
    <Edit
      v-model="dialogs.edit"
      v-bind:dialog="dialogs.edit"
      v-bind:recievedData="dialogs.edit_data"
      v-bind:company="arrays.company"
      @refresh="refresh"  
    ></Edit>
    <Delete
      v-model="dialogs.delete"
      v-bind:dialog="dialogs.delete"
      v-bind:recievedData="dialogs.data"
      @refresh="refresh"
    >
    </Delete>
    <Create
      v-model="dialogs.create"
      v-bind:dialog="dialogs.create"
      v-bind:company="arrays.company"
      @refresh="refresh"
    >
    </Create>
  </v-card>
</template>
<script type="text/javascript">
import Edit from "./edit";
import Delete from "./delete";
import Create from "./create";
import Pagination from "@/components/Pagination";
export default {
  props: ["company", 'myInfo', 'query'],
  components: {
    Edit,
    Delete,
    Create,
    Pagination,
  },
  data() {
    return {
      arrays: {
        users: [],
        company: {},
        supervisors : [],
        me : null
      },
      search: "",
      loading: false,
      headers: [
        { text: "Name", value: "name" },
        { text: "Role", value: "role_description" },
        { text: "Supervisor", value: "parent" , filterable: false, sortable : false },
        { text: "Status", value: "status" , filterable: false, sortable : false },
        { text: "Action", value: "action", filterable: false, sortable : false },
      ],
      dialogs: {
        edit: false,
        delete: false,
        create: false,
        edit_data: null,
      },
      pagination: {
        meta: null,
        current: null,
      },
    };
  },
  watch: {
      search: function (newValue, oldValue) {
        if(newValue.length > oldValue.length)
        {
          this.getUsers(newValue)
        }else{
          if(newValue.length <= 0)
          {
            this.getUsers('')
          }
        }
      }
    },
  created() {
    this.arrays.company = this.company;
 

    if(this.query)
    {
      if(this.query.tab == 'users')
      {
        this.search = this.query.search
      }
    }
       this.getUsers(this.search)
  },
  methods: {

    getUsers(search) {
      if(this.myInfo)
      {
        this.loading = true;
        this.arrays.me = this.myInfo
        this.axios
          .get("/userscompanies?company_id=" + this.arrays.me.default_user_company.company_id+'&fullname='+ search)
          .then(({ data }) => {
            this.loading = false;
            this.arrays.users = this.filterRole(data.users_company);
            this.pagination.meta = data.meta;
          });
      }
    },
    filterRole(users)
    {
      if(users && users.length > 0)
      {
       
        for(let i in users)
        {
          if(!isNaN(i))
          {
            users[i].role_description = this.$options.filters.role(users[i].role)
            users[i].fullname = this.$options.filters.fullname(users[i].user.first_name , users[i].user.last_name)
          }
        }
      }
      return users
      
    },  

    edit(data) {
      this.dialogs.edit_data = data
      this.dialogs.edit = true
    },
    remove(data) {
      this.dialogs.data = data;
      this.dialogs.delete = true;
    },
    create() {
      this.dialogs.create = true;
    },
    refresh() {
      if (this.pagination.current) {
        this.paginate(this.pagination.current);
      } else {
        this.getUsers();
      }
      this.$emit('upateKey', 'users')
    },
    paginate(page) {
      this.pagination.current = page;
      this.loading = true;
      this.axios
        .get(
          "/userscompanies?company_id=" +
             this.arrays.me.default_user_company.company_id+
            "&page=" +
            page
        )
        .then(({ data }) => {
          this.loading = false;
          this.arrays.users = this.filterRole( data.users_company) ;
          if(window.innerWidth < 600)
          {
            document.getElementById('usersTable').scrollIntoView()
          }
        
        });
    },
  },
  computed: {
    filterUsers() {
      return this.arrays.users.filter((data) => {
        return (
          data.fullname.toLowerCase().match(this.search.toLowerCase() ) ||
          data.user.first_name.toLowerCase().match(this.search.toLowerCase() ) ||
          data.user.last_name.toLowerCase().match(this.search.toLowerCase() ) ||
          data.role_description.toLowerCase().match(this.search.toLowerCase() ) ||
          data.status.toLowerCase().match(this.search.toLowerCase() ) || 
          data.parent && data.parent.user.first_name.toLowerCase().indexOf(this.search.toLowerCase() )  > -1  ||
          data.parent && data.parent.user.last_name.toLowerCase().indexOf(this.search.toLowerCase() )  > -1 
        );
      });
    },
  },
};
</script>

<style scoped>
#btn {
  text-transform: none;
}
</style>