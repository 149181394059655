var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('small',{staticClass:"text-caption text-secondary"},[_vm._v("SEARCH")]),_c('v-text-field',{attrs:{"color":"#0A009B","label":"Search","hint":"Enter : Name, Role, Supervisor","persistent-hint":"","prepend-icon":"mdi-account-search-outline"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2","lg":"2"}},[_c('small',{staticClass:"text-caption text-secondary"},[_vm._v("TOTAL USERS")]),_c('p',{staticClass:" h5 black--text mb-0"},[_vm._v(" "+_vm._s(_vm.pagination.meta ? _vm.pagination.meta.total : 0)+" ")])])],1)],1),_c('v-data-table',{attrs:{"id":"usersTable","headers":_vm.headers,"items":_vm.filterUsers,"loading":_vm.loading,"hide-default-footer":true,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.first_name)+" "+_vm._s(item.user.last_name)+" "),(item.user_id == _vm.arrays.me.id)?_c('span',[_vm._v(" ( "),_c('b',[_vm._v("You")]),_vm._v(" ) ")]):_vm._e()]}},{key:"item.parent",fn:function(ref){
var item = ref.item;
return [(item.parent)?_c('span',[_vm._v(" "+_vm._s(item.parent.user.first_name)+" "+_vm._s(item.parent.user.last_name)+" ")]):_c('span',[_vm._v(" N/A ")])]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.role)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 p-1",attrs:{"color":"#0A009B","small":""},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("mdi-pencil")]),(item.user_id != _vm.arrays.me.id)?_c('v-icon',{staticClass:"mr-2 p-1",attrs:{"color":"#9B0A00","small":""},on:{"click":function($event){return _vm.remove(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}}],null,true)}),_c('Pagination',{attrs:{"meta":_vm.pagination.meta},on:{"page":_vm.paginate}}),_c('Edit',{attrs:{"dialog":_vm.dialogs.edit,"recievedData":_vm.dialogs.edit_data,"company":_vm.arrays.company},on:{"refresh":_vm.refresh},model:{value:(_vm.dialogs.edit),callback:function ($$v) {_vm.$set(_vm.dialogs, "edit", $$v)},expression:"dialogs.edit"}}),_c('Delete',{attrs:{"dialog":_vm.dialogs.delete,"recievedData":_vm.dialogs.data},on:{"refresh":_vm.refresh},model:{value:(_vm.dialogs.delete),callback:function ($$v) {_vm.$set(_vm.dialogs, "delete", $$v)},expression:"dialogs.delete"}}),_c('Create',{attrs:{"dialog":_vm.dialogs.create,"company":_vm.arrays.company},on:{"refresh":_vm.refresh},model:{value:(_vm.dialogs.create),callback:function ($$v) {_vm.$set(_vm.dialogs, "create", $$v)},expression:"dialogs.create"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }