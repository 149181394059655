<template>
  <v-card  flat>
    <v-card-text class="pb-0">
       
      <p class="text-caption mb-0 text-dark">DEPARTMENTS</p>
      <v-tabs  color="#0A009B" show-arrows="" >
        <v-tab  @click="active(null) " >all department</v-tab>
        <v-tab
            v-for="department in arrays.departments"
            :key="department.id"
            @click="active(department)"
            class="rounded"
          >
            {{ department.name }}
        </v-tab>
        <v-tab class="text-caption text-center text-lowercase" v-if="status.more" @click="loadMore">
          see more
        </v-tab>
      </v-tabs>
    </v-card-text>
    <v-card-text>
      <v-row dense>
          <v-col cols="12" md="4">
            <small class="text-caption text-secondary">CURRENT DEPARTMENT</small>
            <p class="mb-0 h5 black--text">{{ activeDepartment.name }}</p>
          </v-col>
          <v-col cols="12" md="8" class="">
            <small class="text-caption text-seconday">TOTAL USERS</small>
            <p class="mb-0 h5 black--text">
              {{ pagination.meta ? pagination.meta.total : 0 }}
            </p>
          </v-col>
          <v-col cols="12" md="10">
            <v-text-field
              v-model="search"
              color="#0A009B"
              hide-details="auto"
              label="Search"
              hint="Search for Name, Position, Department"
              persistent-hint
              prepend-icon="mdi-account-search-outline"
              
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" class="text-center">
            <v-btn
              color="#0A009B"
              text
              block
              @click="create"
              min-width="100px"
              :loading="loading.create"
            >
              ADD NEW
            </v-btn>
          </v-col>
        </v-row>
    </v-card-text>
    <v-data-table
      id="usersDepartmentTable"
      :headers="headers"
      :items="filterUsers"
      :hide-default-footer="true"
      :loading="loading.table"
      loading-text="Loading... Please wait"
    >
      <template v-slot:[`item.name`]="{ item }">
        <span v-if="item.user_company">
          {{ item.user_company.user.first_name }}
          {{ item.user_company.user.last_name }}
        </span>
        <span v-else>N/A</span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon color="#0A009B" small class="mr-2 p-1" @click="edit(item)"
          >mdi-pencil</v-icon
        >
        <v-icon color="#9B0A00" small class="mr-2 p-1" @click="remove(item)"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <Pagination v-bind:meta="pagination.meta" @page="paginate"> </Pagination>
    <Edit
      v-model="dialogs.edit"
      v-bind:dialog="dialogs.edit"
      v-bind:data="dialogs.data"
      v-bind:company="arrays.company"
      @refresh="refresh"
      v-bind:departments="arrays.departments"
      v-bind:users="arrays.all_users"
    ></Edit>
    <Delete
      v-model="dialogs.delete"
      v-bind:dialog="dialogs.delete"
      v-bind:data="dialogs.data"
      @refresh="refresh"
    >
    </Delete>
    <Create
      v-model="dialogs.create"
      v-bind:dialog="dialogs.create"
      v-bind:company="arrays.company"
      @refresh="refresh"
      v-bind:departments="arrays.departments"
      v-bind:users="arrays.all_users"
    >
    </Create>
  </v-card>
</template>
<script type="text/javascript">
import Edit from "./edit";
import Delete from "./delete";
import Create from "./create";
import Pagination from "@/components/Pagination";
export default {
  props: ["company", "query"],
  components: {
    Edit,
    Delete,
    Create,
    Pagination,
  },
  data() {
    return {
      arrays: {
        users: [],
        company: {},
        departments: [],
        all_users: [],
      },
      search: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Position", value: "position" },
        { text: "Department", value: "deparments.name" ,filterable: false, sortable : false },
        { text: "Action", value: "action" ,filterable: false, sortable : false },
        
      ],
      dialogs: {
        edit: false,
        delete: false,
        create: false,
        data: null,
      },
      pagination: {
        meta: null,
        current: null,
        department : null,
      },
      activeDepartment: {
        id: null,
        name: 'ALL DEPARTMENT',
      },
      loading: {
        table: true,
        create: true,
      },
      toggle: 'all',
      request: {
        allDepartment: "",
      },
      status : {
        all : false,
        active : null,
        more : false,
        page : 1,
        department_total_page : 0,
      }
    };
  },
  created() {
    this.arrays.company = this.company;
    
    this.getDepartments();
    this.getAllUsers();
    if(this.query)
    {
      if(this.query.tab == 'usersindepartment')
      {
        this.search = this.query.search
      }
    }
  },
  methods: {
    getDepartments() {
      this.loading.table = true;
      this.axios
        .get("/companydepartments?company_id=" + this.arrays.company.id)
        .then(({ data }) => {
          this.arrays.departments = data.company_departments;
          this.getUsersFromDepartment();

          if(data.meta.total > 10)
          {
            // this.status.department_total_page = 
            let pages = data.meta.total / 10
            let remainder = data.meta.total % 10
            this.status.department_total_page = Math.floor(pages) 
            if(remainder)
            {
              this.status.department_total_page++
            }
            this.status.more = true
          }else{
            this.status.more = false
          }
        });
    },
    getUsers(department, page) {
      let p = "";
      if (page) {
        p = page;
      }
      this.activeDepartment.id = department.id;
      this.activeDepartment.name = department.name;
      this.loading.table = true;
      this.axios
        .get(
          "/usercompanydepartments?company_department_ids[0]=" +
            this.activeDepartment.id +
            "&page=" +
            p
        )
        .then(({ data }) => {
          this.loading.table = false;
          this.arrays.users = this.getFullname(data.user_company_departments) ;
          if (!page) {
            this.pagination.meta = data.meta;
          }
          if(page)
          {
            if(window.innerWidth < 600)
            {
              document.getElementById('usersDepartmentTable').scrollIntoView()
            }
          }
        });
    },
    getFullname(datas)
    {
      if(datas && datas.length > 0)
      {
        for(let i in datas)
        {
          if(!isNaN(i))
          {
            datas[i].fullname =  this.$options.filters.fullname(datas[i].user_company.user.first_name , datas[i].user_company.user.last_name)
          }
        }
        return datas
      }
    },
    getUsersFromDepartment() {
      this.arrays.users = [];
      this.pagination.meta = null;
      this.activeDepartment.id = null;
      this.activeDepartment.name = "ALL DEPARTMENT";
      this.mergeURL();
      this.getAll();
    },
    getAll(page) {
      let p = "";
      if (page) {
        p = page;
      }
      this.loading.table = true;
      if (this.arrays.departments.length > 0) {
        this.axios
          .get(
            "/usercompanydepartments?" +
              this.request.allDepartment +
              "&page=" +
              p
          )
          .then(({ data }) => {
            this.loading.table = false;
            this.arrays.users = this.getFullname(data.user_company_departments);
            this.pagination.meta = data.meta;
            if (p) {
              this.pagination.meta.page = p;
              if(window.innerWidth < 600)
              {
                document.getElementById('usersDepartmentTable').scrollIntoView()
              }
            }
          });
      } else {
        this.loading.table = false;
      }
    },
    mergeURL() {
      let i = 0;
      for (let key in this.arrays.departments) {
        if(!isNaN(key))
        {
          this.request.allDepartment +=
            "company_department_ids" +
            "[" +
            i +
            "]=" +
            this.arrays.departments[key].id +
            "&";
          i += 1;
        }
      }
      if (this.request.allDepartment) {
        this.request.allDepartment = this.request.allDepartment.substring(
          0,
          this.request.allDepartment.length - 1
        );
      }
    },
    getAllUsers() {
      let total = null;
      this.axios
        .get("/userscompanies?company_id=" + this.arrays.company.id)
        .then(({ data }) => {
          total = data.meta.total;
          this.axios
            .get(
              "/userscompanies?company_id=" +
                this.arrays.company.id +
                "&per_page=" +
                total
            )
            .then(({ data }) => {
              this.loading.create = false;
              this.arrays.all_users = data.users_company;
            });
        });
    },
    edit(data) {
      this.dialogs.data = data;
      this.dialogs.edit = true;
    },
    remove(data) {
      this.dialogs.data = data;
      this.dialogs.delete = true;
    },
    create() {
      this.dialogs.create = true;
    },
    refresh(recieve) {
      if (recieve == "d") {
        this.pagination.meta.total -= 1;
      }
      if (recieve == "c") {
        this.pagination.meta.total += 1;
      }
      if (!this.activeDepartment.id) {
        this.getAll(this.pagination.current);
      } else {
        this.getUsers(this.activeDepartment, this.pagination.current);
      }
      this.$emit('upateKey', 'users_department')
    },
    active(department) {
      if (department) {
        if(this.status.active == department) return
        this.arrays.users = [];
        this.pagination.meta = null;
        this.request.allDepartment = "";
        this.status.active = department
        this.status.all = false
        this.getUsers(department);

      } else {
        if(!this.status.all)
        {
          this.status.active = null
          this.getUsersFromDepartment();
          this.status.all = true
        }
      }
    },
    paginate(page) {
      this.pagination.current = page;
      if (!this.activeDepartment.id) {
        this.getAll(page);
      } else {
        this.getUsers(this.activeDepartment, page);
      }
    },
    loadMore()
    {
      this.status.page++

      this.axios
        .get("/companydepartments?company_id=" + this.arrays.company.id + '&page=' + this.status.page)
        .then(({ data }) => {
          if(data.company_departments)
          {
            if(data.company_departments.length > 0)
            {
              for(let i in data.company_departments)
              {
                if(!isNaN(i))
                {
                  this.arrays.departments.push(data.company_departments[i])
                }
              }
            
              if(this.status.department_total_page == this.status.page)
              {
                this.status.more = false
              }
            }
          }
      });
    }
  },
  computed: {
    filterUsers() {
      return this.arrays.users.filter((data) => {
          return (
            data.fullname.toLowerCase().match(this.search.toLowerCase() ) ||
            data.user_company.user.first_name.toLowerCase().match(this.search.toLowerCase() ) ||
            data.user_company.user.last_name.toLowerCase().match(this.search.toLowerCase() ) || 
            data.position && data.position.toLowerCase().indexOf(this.search.toLowerCase() )  > -1  ||
            data.deparments.name && data.deparments.name.toLowerCase().indexOf(this.search.toLowerCase() )  > -1 
          );
  
      });
    },
  },
};
</script>

<style scoped>
#btn {
  text-transform: none;
}
</style>