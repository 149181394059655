<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4" lg="4" sm="12" >
          <div class="d-flex align-center justify-content-center p-4 h-100">
            <v-img  height="200" v-if="arrays.company && arrays.company.logo" :src="arrays.company.logo | getBaseURL" class="rounded border"> </v-img>
            <v-img v-else src="@/assets/default.svg" height="200" > </v-img>
          </div>
        </v-col>
        <v-col cols="12" md="8" lg="8" sm="12" class="border-lg-left border-md-left border-sm-0">
          <v-row dense>
            <v-col cols="12" lg="6" md="6">
              <p class="text-caption text-uppercase mb-0">name</p>
              <p class="text-dark"> {{ arrays.company && arrays.company.name ? arrays.company.name : 'N/A' }} </p>
              
              <p class="text-caption text-uppercase mb-0">email</p>
              <p class="text-dark"> {{ arrays.company && arrays.company.company_email ? arrays.company.company_email : 'N/A' }} </p>
              <p class="text-caption text-uppercase mb-0">contact</p>
              <p class="text-dark">
                <span v-if="arrays.company && arrays.company.contact_number">  {{ arrays.company.contact_number | telephone }} </span>
                <span v-else> N/A </span>
              </p>
              <p class="text-caption text-uppercase mb-0">billing date</p>
              <p class="text-dark"> 
                <span v-if="arrays.company && arrays.company.billing_date"> {{ arrays.company.billing_date | date }} </span>
                <span v-else> N/A</span>
              </p>
             
            </v-col>
            <v-col cols="12" lg="6" md="6">
              <p class="text-caption text-uppercase mb-0">status</p>
              <p class="text-dark"> {{arrays.company && arrays.company.status ? arrays.company.status : 'N/A' }} </p>
              
              <p class="text-caption text-uppercase mb-0">Country</p>
              <p class="text-dark"> {{arrays.company && arrays.company.country ? arrays.company.country : 'N/A'  }} </p>
              <p class="text-caption text-uppercase mb-0">address</p>
              <p class="text-dark"> {{arrays.company && arrays.company.address ? arrays.company.address : 'N/A' }} </p>
              <p class="text-caption text-uppercase mb-0 text-truncate">timezone</p>
              <p class="text-dark"> {{arrays.company && arrays.company.timezone ? arrays.company.timezone.timezone : 'N/A' }} </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="border-top">
      <div class="w-100 text-right">
        <v-btn text color="#0A009B" @click="edit" :loading="loading">edit </v-btn>
      </div>
    </v-card-actions>
    <v-overlay :absolute="true" v-if="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <Edit v-bind:myInfo="arrays.me" v-bind:company="arrays.company" v-model="dialog.edit" v-bind:dialog="dialog.edit" @refresh="refresh"> </Edit>
  </v-card>
</template>
<script>
import Edit from "@/components/Manage/CompanyDetails/edit";
export default {
  components: {
    Edit,
  },
  props: ['company', 'myInfo'],
  data: () => ({
    arrays : {
      me : null,
      company : null,
    },
    dialog: {
      edit : false
    },
    loading : true,
    overlay : true,
  }),
  watch: {
    company : function(val)
    {
      if(val)
      {
        this.arrays.company = val
        this.overlay = false
        this.loading = false
      }
    },
    myInfo : function(val)
    {
      if(val)
      {
        this.arrays.me = val
        this.overlay = false
        this.loading = false
      }
    }
  },
  created() {
    if(this.company)
    {
      this.arrays.company = this.company
      this.overlay = false
    }
    if(this.myInfo)
    {
      this.arrays.me = this.myInfo
      this.overlay = false
      this.loading = false
    }
  },
  methods: {
    edit()
    {
      this.dialog.edit = true
    },
    refresh()
    {
      this.$emit('refresh', true)
    }
  },
};
</script>

<style scoped>
#btn {
  text-transform: none;
}

</style>