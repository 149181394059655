<template>
  <div class="text-center">
    <v-snackbar v-model="snackbar" top right color="error" >
     <v-icon class="mr-2" >mdi-close</v-icon>
     <span> Failed to Create! </span>
   </v-snackbar>
    <v-dialog v-model="show" width="400" persistent>
      <v-card>
        <v-toolbar color="#00439B" elevation="0">
          <v-toolbar-title class="white--text">New Department</v-toolbar-title>
        </v-toolbar>
        <v-form ref="form" @submit.prevent="submit" lazy-validation>
          <v-card-text>
   
                <v-text-field
                  label="Company Name"
                  required
                  :rules="rules.company"
                  v-model="form.company"
                  readonly
                  
                >
                </v-text-field>
        
                <v-text-field
                  label="Department"
                  required
                  :rules="rules.department"
                  v-model="form.name"
                  :disabled="overlay"
                >
                </v-text-field>
             <small class="mb-0 text-danger border px-2  rounded py-1 border-danger" v-if="status.message"> <b> {{ status.department }} </b> already exist. please try again </small>
          </v-card-text>
          <v-card-actions class="border-top">
            <div class="w-100 text-right">
              <v-btn class="text-secondary" text @click="cancel" :disabled="overlay"> Cancel </v-btn>
              <v-btn type="submit" text color="#0A009B" :loading="overlay"> submit </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script type="text/javascript">
export default {
  props: {
    dialog: Boolean,
    recievedData: Object,
    list : Array,
    company : Object,
  },
  data() {
    return {
      rules: {
        department: [(value) => !!value || "Please provide a department"],
        company: [(value) => !!value || "Please select a company"],
      },
      form: {
        company_id: null,
        name: null,
        company: null,
      },
      status : {
         message : false,
         department : null
      },
      arrays: {
        company: [],
        department : null,
      },
      overlay: false,
      snackbar : false
    };
  },
  computed: {
    show: {
      get() {
        if (this.recievedData) {
          this.populate();
          
        }
        return this.dialog;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    cancel() {
      this.show = false;
      this.form.name = null
      this.$refs.form.resetValidation();
    },
    submit() {
      if (this.$refs.form.validate()) {
       
        this.overlay = true;  
        this.axios.get('/companydepartments?company_id=' + this.arrays.company.id +'&name='+ this.form.name )
        .then( ({data}) => {
          if(data.company_departments && data.company_departments.length > 0)
          {
            this.overlay = false
            this.status.message = true
            this.status.department = this.form.name
          }else{
            this.status.message = false
            this.createDepartment()
          }
        })
      }
    },
    createDepartment()
    {
        this.axios.post("/companydepartments", this.form)
        .then(() => {
          this.cancel();
          this.overlay = false
          let data = {
            snackbar : {
              message : 'New Department Created!'
            }
          }
          this.$emit("refresh", data);
        })
        .catch( () => {
          this.snackbar = true
        })
    },
    populate() {
      
      this.form.company = this.recievedData.name;
      this.form.company_id = this.recievedData.id;
      this.arrays.department = this.list
      this.arrays.company = this.company
     
    },
  },
};
</script>