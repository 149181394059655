<template>
  <div class="text-center">
    <v-dialog v-model="show" max-width="600px" persistent>
      <v-card>
        <v-overlay :absolute="true" :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-form ref="form" @submit.prevent="submit" lazy-validation>
          <v-card-title class="headline primary lighten-2">
            New User in Company
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Company"
                  readonly
                  :rules="rules.company"
                  v-model="form.company"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Name"
                  readonly
                  required
                  :rules="rules.department"
                  v-model="form.name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Status"
                  readonly
                  required
                  :rules="rules.status"
                  v-model="form.status"
                >
                </v-text-field>
                <!-- <v-select label="Status" :items="status" v-model="form.status" readonly></v-select> -->
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Role"
                  required
                  :rules="rules.department"
                  v-model="form.role"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Parent ID"
                  required
                  :rules="rules.department"
                  v-model="form.parent_id"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <timezone
                  @select="selectTimezone"
                  v-bind:id="form.timezone_id"
                  v-bind:rules="rules.timezone_id"
                >
                </timezone>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="m-0"></v-divider>
          <v-card-actions class="p-3">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="cancel"> Cancel </v-btn>
            <v-btn type="submit" color="primary"> Submit </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script type="text/javascript">
import timezone from "@/components/Timezone";
export default {
  components: {
    timezone,
  },
  props: {
    dialog: Boolean,
    recievedData: Array,
  },
  data() {
    return {
      form: {
        id: null,
        users_id: null,
        company_id: null,
        role: null,
        parent_id: null,
        status: "active",
        timezone_id: null,
        currentUser: null,
        team_dashboard: null,
      },
      rules: {
        users_id: [(value) => !!value || "Please select user"],
        role: [(value) => !!value || "Please provide role"],
        timezone_id: [(value) => !!value || "Please select timezone"],
        department: [(value) => !!value || "Please provide a department"],
        company: [(value) => !!value || "Please provide company"],
      },
      overlay: false,
    };
  },
  computed: {
    show: {
      get() {
        if (this.recievedData) {
          this.populate();
        }
        return this.dialog;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        console.log(this.form);
      }
      console.log(this.form);
    },
    cancel() {
      this.show = false;
      this.$refs.form.resetValidation();
    },
    selectTimezone(id) {
      this.form.timezone_id = id;
    },
    populate() {
      this.form.company_id = this.recievedData.company_id;
      this.form.id = this.recievedData.id;
    },
  },
};
</script>