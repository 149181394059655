<template>
  <div class="text-center">
    <v-dialog v-model="show" max-width="500px" persistent>
      <v-card>
         <v-toolbar color="#00439B" elevation="0">
        <v-toolbar-title class="white--text">Update user in company</v-toolbar-title>
        </v-toolbar>
        <v-form ref="form" @submit.prevent="update" lazy-validation>
          <v-card-text>
  
                <v-text-field
                  label="Company"
                  readonly
                  :rules="rules.company"
                  v-model="form.company"
                  :disabled="overlay"
                >
                </v-text-field>
             
                <v-select
                  :items="arrays.users"
                  :rules="rules.user"
                  label="Users"
                  :item-text="getName"
                  item-value="id"
                  v-model="form.user_company_id"
                  :disabled="overlay"
                >
                </v-select> 
      
                <v-select
                  :items="arrays.departments"
                  :rules="rules.department"
                  label="Department"
                  item-text="name"
                  item-value="id"
                  v-model="form.company_department_id"
                  :disabled="overlay"
         
                >
                </v-select>
          
                <v-text-field
                  label="Position"
                  :rules="rules.position"
                  v-model="form.position"
                  :disabled="overlay"
                >
                </v-text-field>

          </v-card-text>
          <v-card-actions class="border-top">
            <div class="text-right w-100">
              <v-btn class="text-secondary" text @click="cancel" :disabled="overlay"> Cancel </v-btn>
              <v-btn text type="submit" color="#0A009B" :loading="overlay"> update </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script type="text/javascript">
export default {
  components: {},
  props: {
    dialog: Boolean,
    company: Object,
    departments: Array,
    users: Array,
    data: Object,
  },
  data() {
    return {
      arrays: {
        departments: [],
        users: [],
      },
      form: {
        id: null,
        user_company_id: null,
        company_department_id: null,
        position: null,
        company: null,
      },
      rules: {
        department: [(value) => !!value || "Please provide a department"],
        company: [(value) => !!value || "Please provide company"],
        user: [(value) => !!value || "Please select a user"],
        position: [(value) => !!value || "Please provide position"],
      },
      overlay: false,
    };
  },
  computed: {
    show: {
      get() {
        if (this.company && this.departments && this.users && this.data) {
          this.populate();
        }
        return this.dialog;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    update() {
      if (this.$refs.form.validate()) {
        this.overlay = true;
        this.axios
          .put("/usercompanydepartments/" + this.form.id, this.form)
          .then(() => {
            this.cancel();
            this.overlay = false;
            this.$emit("refresh", true);
          });
      }
    },
    cancel() {
      this.show = false;
      this.form.company_department_id = null;
      this.form.user_company_id = null;
      this.form.position = null;
      this.$refs.form.resetValidation();
    },
    selectTimezone(id) {
      this.form.timezone_id = id;
    },
    populate() {
      if(this.company)
      {
        this.form.company = this.company.name;
        this.arrays.departments = this.departments;
        this.arrays.users = this.users;
        this.form.id = this.data.id;
        this.form.user_company_id = this.data.user_company_id;
        this.form.company_department_id = this.data.company_department_id;
        this.form.position = this.data.position;
      }
      
    },
    getName(item) {
      return `${item.user.first_name} ${item.user.last_name}`;
    },
  },
};
</script>